// Font Family 
import './static/font/all.min.css';
import './static/font/stylesheet.css';
// Bootstrap
import "././node_modules/bootstrap/dist/css/bootstrap.min.css"
import "././node_modules/bootstrap/dist/js/bootstrap.bundle"
// Style Sheets
import './src/styles/theme.scss';
// Scroll
import ReactDOM from "react-dom/client"

export const onRouteUpdate = ({ location, prevLocation }) => {
    window.scrollTo(0, 0);
};

export const replaceHydrateFunction = () => {

    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}
